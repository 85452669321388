/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Helmet } from 'react-helmet';

function MetaPixel() {
  return (
    <Helmet>
      <title> 3 Semanas para o Vestido - Jéssica Vilela </title>
      <script>
        {`
          !function(f,b,e,v,n,t,s) {
            if(f.fbq) return; 
            n=f.fbq=function(){ 
              n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq=n; 
            n.push=n; 
            n.loaded=!0; 
            n.version='2.0'; 
            n.queue=[]; 
            t=b.createElement(e);
            t.async=!0; 
            t.src=v; 
            s=b.getElementsByTagName(e)[0]; 
            s.parentNode.insertBefore(t,s)
          }(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
          
          fbq('init', '1085095403154617'); 
          fbq('track', 'PageView');
        `}
      </script>
    </Helmet>
  );
}

export default MetaPixel; 