import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import "video-react/dist/video-react.css";
// import { BigPlayButton, Player } from 'video-react';
// import { BigPlayButton, Player } from 'video-react';

// import { linkVSL } from '../constants';
// import poster from "../assets/images/poster_video.png";
// import { Helmet } from 'react-helmet';

function VideoVSL() {
    const [pageDimension] = useState(() => (
    {
      width: window.innerWidth >= 990 ? 394 : 320,
      height: window.innerWidth >= 990 ? 675 : 550 
    }
  ));

  return (
    <>
     {/* <iframe 
        width={pageDimension.width}
        height={pageDimension.height} 
        src="https://www.youtube.com/embed/TDWS2Q1sARA?autoplay=1" 
        title="3 Semanas para o Vestido" 
        frameborder="1" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen="false">
      </iframe> */}
      <Helmet>
        <link rel="preload" href="https://player-vz-b58e0d2e-25e.tv.pandavideo.com.br/embed/css/styles.css" as="style" />
        <link rel="prerender" href="https://player-vz-b58e0d2e-25e.tv.pandavideo.com.br/embed/?v=cf4b6ddf-2121-41d4-b507-686d0f29daac" />
        <link rel="preload" href="https://player-vz-b58e0d2e-25e.tv.pandavideo.com.br/embed/js/hls.js" as="script" />
        <link rel="preload" href="https://player-vz-b58e0d2e-25e.tv.pandavideo.com.br/embed/js/plyr.polyfilled.min.js" as="script" />
        <link rel="preload" href="https://config.tv.pandavideo.com.br/vz-b58e0d2e-25e/cf4b6ddf-2121-41d4-b507-686d0f29daac.json" as="fetch" />
        <link rel="preload" href="https://config.tv.pandavideo.com.br/vz-b58e0d2e-25e/config.json" as="fetch" />
        <link rel="preload" href="https://b-vz-b58e0d2e-25e.tv.pandavideo.com.br/cf4b6ddf-2121-41d4-b507-686d0f29daac/playlist.m3u8" as="fetch" />
        <link rel="dns-prefetch" href="https://b-vz-b58e0d2e-25e.tv.pandavideo.com.br" />
        <link rel="dns-prefetch" href="https://player-vz-b58e0d2e-25e.tv.pandavideo.com.br" />
        <link rel="dns-prefetch" href="https://vz-b58e0d2e-25e.b-cdn.net" />
      </Helmet>
    {/* <Player
      playsInline
      poster={poster}
      autoPlay={true}
      src={linkVSL}
      fluid={false}
      width={pageDimension.width}
      height={pageDimension.height}
    >
      <BigPlayButton position="center" />
    </Player> */}
     <iframe 
      title="vsl"
      id="panda-f0dd187e-d845-4054-b2b7-fe83b5fa41d3"
      src="https://player-vz-b58e0d2e-25e.tv.pandavideo.com.br/embed/?v=f0dd187e-d845-4054-b2b7-fe83b5fa41d3"
      style={{"border": 'none' }}
      // allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture"
      allowFullScreen={true}
      width={pageDimension.width}
      height={pageDimension.height}
      fetchpriority="high"></iframe>
    </>
  );

}

export default VideoVSL; 