import React from "react";
import { Route, Switch } from "react-router-dom";
import TresPV from "./pages/TresPV";
import Home from "./pages/Home";

const Router = () => {
  return (
    <Switch>
        <Route path="/3semanasparaovestido">
          <TresPV />
        </Route>
        <Route path="/" exact>
          <Home />
        </Route>
    </Switch>
  );
}

export default Router;