import React from 'react';
import { AiFillInstagram, AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from 'react-router-dom';

function Header({ returnButton }) {
  return (
    <header>
      {returnButton && 
        <Link to="/"
          className="absolute flex items-center justify-center left-5 top-5 h-8 w-8
          bg-bege text-bordo rounded-xl shadow-lg md:left-10 md:top-5
          hover:scale-105 transition-scale duration-200">
          <AiOutlineArrowLeft className="text-2xl" />
        </Link>
      }
      <a href="https://www.instagram.com/jessicavilelar/"
        className="absolute flex items-center justify-center right-5 top-5 h-8 w-8
        bg-bordo text-white rounded-xl shadow-lg md:right-10 md:top-5
        hover:scale-105 transition-scale duration-200">
        <AiFillInstagram className="text-2xl" />
      </a>
    </header>
  );
}

export default Header;