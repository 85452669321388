import idv from "../assets/images/JessicaVilela_IDV_Completa_Preferencial_BG_Offwhite-removebg-SM.png";
import Header from "../components/Header";
import { Link } from 'react-router-dom';
import perfil_1 from "../assets/images/perfil_1.png";

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Header returnButton={false} />
      <div className="flex flex-col items-center justify-center">
        <div className="gradient rounded-[4px]">
          <img src={perfil_1} alt="jessica vilela" className="w-[400px] z-10 rounded-[4px]"/>
        </div>
        <img src={idv} alt="jessica vilela logo" className="w-[240px] mt-5 mb-5"/>
        <h1 className="text-3xl text-zinc-700 sr-only"> Jessica <strong>Vilela</strong> </h1>
      </div>

      <div className="flex flex-col space-y-5">
        <a href="https://wa.me/5585992958888" className="px-5 py-5 w-64 text-center text-lg rounded-md text-white bg-bordo
            hover:bg-laranja duration-300 shadow-lg"> Agende sua consulta </a>
        <Link to="/3semanasparaovestido" className="px-5 py-5 w-64 text-center text-lg rounded-md text-white bg-bordo
            hover:bg-laranja duration-300 shadow-lg"> 3 Semanas para o Vestido </Link>
      </div>
    </div>
  );
}

export default Home;
