import Router from "./router";
// 1. import `NextUIProvider` component
import {NextUIProvider} from "@nextui-org/react";

const App = () => {
  return (
    <NextUIProvider>
      <Router />
    </NextUIProvider>
  );
}

export default App;
