import React from "react";
import { 
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "@nextui-org/react";
import { linkCloudFunction, linkPlanner } from "../constants";

export default function ModalDownloadPlanner( { isOpen, onOpenChange }) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);

  const isEmailInvalid = React.useMemo(() => {
    if (email === "") return false;

    const validateEmail = (value) => value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);
    return validateEmail(email) ? false : true;
  }, [email]);

  const addLead = (onClose) => {
    setLoading(true);

    fetch(`${linkCloudFunction}/addLead`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email })
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
    .then(() => {
      setName('');
      setEmail('');
      onClose();
        const file_path = linkPlanner;
        const filename = 'Planner_21dias-JessicaVilela.pdf';
        const a = document.createElement('a');

        a.href = file_path;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      <Modal backdrop={'blur'} isOpen={isOpen} onOpenChange={onOpenChange} placement={'center'}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 w-full"> Preencha os campos </ModalHeader>
              <ModalBody>
                <form id="lead-form" className="flex flex-col gap-3">
                  <Input
                    isRequired
                    value={name}
                    type="text"
                    placeholder="Seu nome"
                    label="Nome"
                    variant="bordered"
                    onValueChange={setName}
                  />
                  <Input
                    isRequired
                    value={email}
                    type="email"
                    placeholder="Insira seu email"
                    label="Email"
                    variant="bordered"
                    isInvalid={isEmailInvalid}
                    color={isEmailInvalid ? "danger" : ""}
                    errorMessage="Insira um email válido"
                    onValueChange={setEmail}
                    />
                </form>
              </ModalBody>
              <ModalFooter>
                <Button variant="light" onPress={onClose}>
                  Sair
                </Button>
                <Button color="primary" isDisabled={isLoading || isEmailInvalid || email === ''} onClick={() => addLead(onClose)}>
                  {!isLoading ? 'Baixar': 'Carregando...'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}