import React from "react";
import { 
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "@nextui-org/react";

import { useMask } from '@react-input/mask';
import { linkCloudFunction, linkKirvanoCheckout3SPV } from "../constants";


export default function ModalLead({ isOpen, onOpenChange } ) {
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const inputPhoneRef = useMask({ mask: '(__) _____-____', replacement: { _: /\d/ } });

  const isPhoneInvalid = React.useMemo(() => {
    if (phone === "") return false;

    return phone.length === 15 ? false : true;
  }, [phone]);

  const addLead = (onClose) => {
    setLoading(true);

    fetch(`${linkCloudFunction}/addPurchaseLead`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, phone })
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
    .then(() => {
      setName('');
      setPhone('');
      onClose();
      window.location.href = linkKirvanoCheckout3SPV;
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      window.location.href = linkKirvanoCheckout3SPV;
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <Modal backdrop={'blur'} isOpen={isOpen} onOpenChange={onOpenChange} placement={'center'}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 w-full"> Preencha os campos </ModalHeader>
            <ModalBody>
              <form id="lead-form" className="flex flex-col gap-3">
                <Input
                  isRequired
                  value={name}
                  type="text"
                  placeholder="Seu nome"
                  label="Nome"
                  variant="bordered"
                  onValueChange={setName}
                />
                <Input
                  isRequired
                  value={phone}
                  type="tel"
                  placeholder="Número com DDD"
                  label="Telefone (WhatsApp)"
                  variant="bordered"
                  isInvalid={isPhoneInvalid}
                  color={isPhoneInvalid ? "danger" : ""}
                  errorMessage="Insira um telefone válido (com ddd e 9 numeros)"
                  onValueChange={setPhone}
                  ref={inputPhoneRef}
                />
              </form>
            </ModalBody>
            <ModalFooter>
              <Button variant="light" onPress={onClose}>
                Sair
              </Button>
              <Button color="primary" isDisabled={isLoading || isPhoneInvalid || phone === ''} onClick={() => addLead(onClose)}>
                {!isLoading ? 'Avançar': 'Carregando...'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}